.section{
    overflow: hidden;
}

.partners{
    max-width: 100%;
    margin-left: 15%;
    min-height: 100vh;
    transition: all .2s;
    animation: fadeIn 1s ease-out .2s;
        animation-fill-mode: backwards;
    display: flex;
    justify-content: center;
    width: 85%;
    flex-direction: column;
    box-sizing: border-box;
    padding-top: calc(#{$nav-height} + 5rem);
    padding-bottom: 5rem;

    @media only screen and (max-width:$tablet-XL){
        padding-top: calc(#{$nav-mobile-height} + 5rem);
    }

    @media only screen and (max-width:$tablet-L){
        animation: fadeIn 1s ease-out .2s;
        animation-fill-mode: backwards;
    }

    @media only screen and (max-width:$mobile-L){
        margin-left: 10%;
        padding-top: calc(#{$nav-mobile-S-height} + 8%);
        justify-content: flex-start;
    }
    &__header{
        display: grid;
        grid-template-columns: min-content 1fr;
        grid-row-gap: 2rem;
        margin-bottom: 3rem;
       
    }

    &__icon{
        width: 10rem;
        @media only screen and (max-width:$tablet-S){
            width: 7rem;
        }
        @media only screen and (max-width:$mobile-XL){
            width: 5rem;
        }
    }

    &__title{
        font-weight: lighter;
        font-size: 2.6rem;
        align-self: end;
        @media only screen and (max-width:$tablet-S){
            font-size: 2.3rem;
        }

        @media only screen and (max-width:$mobile-XL){
            font-size: 1.8rem;
        }
        @media only screen and (max-width:$mobile-L){
            font-size: 1.6rem
        }
    }

    &__desc{
        grid-column: 1/-1;
        font-size: 1.8rem;
        margin-left: 2rem;
        width: 54%;

        @media only screen and (max-width:$tablet-XL){
            width: 80%;
        }
        @media only screen and (max-width:$tablet-S){
            font-size: 1.6rem;
        }
        @media only screen and (max-width:$mobile-XL){
            font-size: 1.4rem;
        }
        @media only screen and (max-width:$mobile-L){
            font-size: 1.3rem
        }
    }

    &__content{
        display: grid;
        grid-template-rows: repeat(3,max-content);
        grid-template-columns: 80%;
        width: 100%;
        margin-top: 2rem;
        @media only screen and (max-width:$mobile-L){
            grid-template-columns: 90%;   
        }
    }

    &__cards {
        display: grid;
        grid-template-columns: repeat(3,max-content);
        width: 80%;
        grid-column-gap: 4rem;
        margin-left: 2%;
        @media only screen and (max-width:$mobile-L){
            justify-content: center;
            grid-template-columns: 1fr;
            grid-row-gap: 2rem;
            margin: auto;
        }
    }

    &__layer{
        position: absolute;
        right: 5%;
        transform: rotate(21deg) translateX(-11%);
        width: 5%;
        z-index: -1;
    }

    &__btn{
        width: max-content;
        cursor: pointer;
        margin-top: 1rem;
        border: none;
        border-radius: 10rem;
        padding: 1.8rem 5.1rem;
        font-size: 1.7rem;
        background-image: linear-gradient(to bottom, $color-primary-light, $color-primary-darker);
        color: #fff;
        box-shadow: 0 5px 1rem rgba(0,0,0,.1);
        margin-left: 2rem;
        margin-top: 5rem;
        text-decoration: none;
        transition: all .2s;

        &:hover{
            transform: scale(1.03) translateY(-2px);
            box-shadow: 0 5px 1rem rgba(0,0,0,.2);
        }

        &:focus{
            transform: scale(1) translateY(0);
            box-shadow: 0 2px 1rem rgba(0,0,0,.1);
        }

        @media only screen and (max-width:$mobile-L){
            font-size: 1.4rem;
            width: 80%;
            text-align: center;
            margin: 3rem auto 0;
        }
    }
}