.navigation{
    width: 100vw; 
    max-width: 100%;
    height: $nav-height;
    display: flex;
    position: absolute;
    top: 0;
    justify-content: space-evenly;
    align-items: center;
    background-color: $color-primary-dark;
    z-index: 999;
    animation: fadeIn 1s ease-out;

    @media only screen and (max-width:$tablet-XL){
        justify-content: space-between;
        height: $nav-mobile-height;
    }

    @media only screen and (max-width:$mobile-L){
        height: $nav-mobile-S-height;
    }


    &--logo-container{
        align-self: flex-start;
        background-color: #fff;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        width: max-content;
        display: flex;
        height:12rem;
        width: 15%;
        justify-content: center;
        align-items: flex-end;
        position: relative;

        @media only screen and (max-width:$tablet-XL){
            margin-left: 3vw;
            height: 11rem;
            width: 15rem;
        }

        @media only screen and (max-width:$mobile-L){
            height: 8rem;
        }

        img{
            width: 100%;
            margin-bottom: .5rem;

            @media only screen and (max-width:$tablet-XL){
                margin-bottom: 1rem;
            }

            @media only screen and (max-width:$mobile-L){
                width: 90%;
            }
        }   
    }

    &--btn{
        display: none ;
        position: absolute;
        width:8rem;
        height: 8rem;
        right: 2rem;
        top: 50%;
        transform: translateY(-50%);
        justify-content: center;
        align-items: center;
        cursor: pointer;
        z-index: 40;
        @media only screen and (max-width:$tablet-XL){
            display: flex;
        }

        @media only screen and (max-width:$mobile-L){
            right: 5px;
         }

    }

    &--small{
        width: 4.5rem;
        background-color: #fff;
        border-radius: 10rem;
        height: 7px;
        position: relative;
        transition: all .2s;

        @media only screen and (max-width:$mobile-L){
            width: 4rem;
         }

         @media only screen and (max-width:$mobile-S){
            width: 3.5rem;
         }
        &::before,
        &::after{
            content: "";
            width: 4.5rem;
            border-radius: 10rem;
            height: 7px;
            position: absolute;
            background-color: #fff;
            transition: all .2s;
            @media only screen and (max-width:$mobile-L){
                width: 4rem;
             }

             @media only screen and (max-width:$mobile-S){
                width: 3.5rem;
             }
        }

        &::before{
            top: -1.3rem;
        }

        &::after{
            bottom: -1.3rem;
        }
    }

    &--active.navigation--small{
        background: transparent;

        &::before,
        &::after{
            top: 0;
            background-color: $color-primary-dark;
        }
        
        &::before{
            transform: rotate(45deg);
        }

        &::after{
            transform: rotate(-45deg);
        }
    }

    &--menus{
        transform: translateX(100%);
        background-color: #fff;
        height: 100%;
        width: 30rem;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 30;
        box-shadow: 0 1rem 3rem rgba(0,0,0,.1);
        transition: all .2s;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        display: none;
        // z-index: 10;
        

        @media only screen and (max-width:$tablet-XL){
            display: flex;
        }
        @media only screen and (max-width:$mobile-L){
            width: 20rem;
         }
    }

    &--active.navigation--menus{
        transform: translateX(0);
        opacity: 1;
        visibility: visible;
    }

    &--container{
        display: flex;
        width: 50%;
        justify-content: space-between;
        color: #fff;
        font-size: 1.8rem;
        line-height: 1.4;
        font-weight: 400;

        @media only screen and (max-width:$desktop-L){
            width: 55%;
        }
        @media only screen and (max-width:$desktop-M){
            width: 60%;
        }
        @media only screen and (max-width:$desktop-S){
            width: 65%;
        }

        @media only screen and (max-width:$tablet-XL){
            display: none;
        }
    }

    &__menu{
        cursor: pointer;
        transition:  all .7s;
        padding: .5rem 1.8rem;
        text-decoration: none;
        color: #fff;
        outline: none;
        margin: 0 4px;

        @media only screen and (max-width:$tablet-XL){
            font-size: 2rem;
            color: $color-primary-dark;
            margin-bottom: 2rem;
            padding: 1.3rem 2.5rem;
        }


        @media only screen and (max-width:$mobile-L){
            font-size: 1.5rem;
         }
         @media only screen and (max-width:$mobile-S){
            margin-bottom: 1rem;
         }


        &:hover{
            border-radius: 100px;
            background-color: #fff;
            color: $color-primary-dark;

            @media only screen and (max-width:$tablet-XL){
                background-color: $color-primary-dark;
                color: #fff;
            }
        }   

        &--active{
            border-radius: 100px;
            background-color: #fff;
            color: $color-primary-dark;

            @media only screen and (max-width:$tablet-XL){
                background-color: $color-primary-dark;
                color: #fff;
            }
        } 
    }


    &--transparent{
        background-image: none;
        background-color: transparent;      
    }
}


