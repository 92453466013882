// "fixed w-screen z-20 h-screen left-0 bg-black/20 dark:bg-white/20  lg:hidden cursor-pointer"

.background-filter {
    position: fixed;
    width: 100%;
    height: 200%;
    z-index: 20;
    left: 0;
    background-color: black;
    opacity: 0.5;
    cursor: pointer;
}