.section{
    overflow: hidden;
}
.home{
    height: 100vh;
    width: 100vw;
    max-width: 100%;
    display: flex;
    align-items: center;
    background-color: $color-primary-dark;
    background-size: contain;
    background-repeat: no-repeat;
    overflow: hidden;
    position: relative;
    background-image: url(../../assets/homeBackground.png), linear-gradient($color-primary-dark,$color-primary-dark);
    background-size: contain;
    background-repeat: no-repeat;

    @media only screen and (max-width:$tablet-L){
        background-color: auto;
        background-size: cover;
    }
    &__layer{
        position: absolute;
        width: 30%;
        right: 10%;
        bottom: 10%;
        transform: rotate(90deg);

        @media only screen and (max-width:$desktop-M){
            display: none;
        }
    }

    

    &__bg{
        position: absolute;
        width: 85vw;
        top: 1rem;
        left: 0;
    }
   
    &__left{
        z-index: 10;
        width: 80vw;
        margin-left:14%;
        color:#fff;
        animation: moveInLeft 1s ease-out .2s;
        animation-fill-mode: backwards; 
        display: grid;
        grid-template-columns: min-content 76%;
        grid-template-rows: max-content max-content;
        align-items: end;

        @media only screen and (max-width:$tablet-L){
            margin-right: 14%;
            animation: fadeIn 1s ease-out .2s;
            animation-fill-mode: backwards;
        }
    }

    &__number-container{
        grid-row: 1/2;
        grid-column: 1/2;
        display: flex;
        align-items: end;
        
    }

    &__icon-number{
        font-size: 6rem;
        font-weight: lighter;
        text-align: end;
        margin-bottom: -1.5rem;
        margin-top: auto;

        @media only screen and (max-width:$tablet-S){
            font-size: 4rem;
            margin-bottom: -.5rem;
        }

    }

   

    &__number{
        font-size: 24rem;
        margin-bottom: -7.5rem;
        font-weight: lighter;
        margin-left: -2rem;

        @media only screen and (max-width:$tablet-S){
            font-size: 20rem;
            margin-bottom: -5.5rem;
        }

        @media only screen and (max-width:$mobile-XL){
            font-size: 17rem;
            margin-bottom: -4.8rem;
        }
    }

    &__text{
        font-size: 2.4rem;
        
        // @media only screen and (max-width:$tablet-XL){
        //     br{
        //         display: none;
        //     }
        // }

        @media only screen and (max-width:$tablet-S){
            font-size: 2rem;
            br{
                display: none;
            }
        }

        @media only screen and (max-width:$mobile-XL){
            font-size: 1.8rem;
        }

        @media only screen and (max-width:$mobile-L){
            font-size: 1.6rem;
        }

        @media only screen and (max-width:$mobile-M){
            font-size: 1.4rem;
            line-height: 1.8rem;
        }

        @media only screen and (max-width:$mobile-S){
            font-size: 1.2rem;
        }
    }

    &__button{
        border: none;
        border-radius: 1rem;
        color: $color-grey-dark-3;
        font-weight: bold;
        padding: 1rem 3rem;
        font-size: 1.5rem;
        cursor: pointer;
        transition: all .2s;
        margin-top: 2.2rem;
        background-color: #fff;
        text-decoration: none;
        grid-column: 2/-1;
        justify-self: start;
        outline: none;
        @media only screen and (max-width:$tablet-L){
            grid-column: 1/-1;
            margin-left: auto;
            margin-right: auto;
        }

        @media only screen and (max-width:$mobile-XL){
            font-size: 1.2rem;
        }


        &:hover{
            transform: translateY(-3px) scale(1.05);
            box-shadow: 0 1rem 3rem rgba(0,0,0,.1);
            color: rgba($color-primary-dark, .7);
            outline: none;
        }

        &:active{
            transform: translateY(0) scale(1);
            box-shadow: 0 .5rem 4rem rgba(0,0,0,.1);
            color:  $color-primary-dark;
            background-color: $color-grey-light-1;
            outline: none;
        }
    }

    &__right{
        z-index: 10;
        margin-top: calc(#{$nav-height} + 5rem);
        height: 100%;
        display: flex;
        flex-direction: column;
        margin-right: 10%;
        position: relative;
        @media only screen and (max-width:$tablet-L){
            display: none;
        }
    }

    &__smartphone{
        height: 75%;
        align-self: flex-end;
    }

    &__download{
        position: absolute;
        bottom: 0;
        right: 15%;
        width: 25vw;
        height: 10%;
        cursor: pointer;
        
    }

    &__wave{
        position: absolute;
        z-index: 5;
        width: 400%;
        left: -215%;
    }

    &__invisible{
        cursor: pointer;
        background: transparent;
        width: 100%;
        height: 100%;
        @media only screen and (max-width:$tablet-L){
            display: none;
        }
    }
}

