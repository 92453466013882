.card{
    background-color: #fff;
    width: 50vw;
    // width: 77rem;
    height: 48rem;
    border-radius: 2rem;
    padding-top: 13rem;
    padding-left: 6.5rem;
    padding-right: 6.5rem;
    padding-bottom: 3rem;
    transform: translateX(10vw) translateY(5%);
    position: relative;
    z-index: 10;
    margin-top: 25%;
    margin-bottom: 25%;
    animation: fadeIn 1s ease-out .2s;
        animation-fill-mode: backwards;

    @media only screen and (max-width:1100px){
        width: 70vw;
        transform: translateX(-1%);
    }

    @media only screen and (max-width:800px){
        height: 35rem;
        padding-top: 9rem;
    }

    @media only screen and (max-width:$mobile-XL){
        padding-left: 5rem;
        padding-right: 5rem;
        padding-top: 7rem;
    }

    @media only screen and (max-width:500px){
        width: 80vw;
        transform: translateX(-5%);
    }
    @media only screen and (max-width:$mobile-L){
        padding-left: 4rem;
        padding-right: 4rem;
        padding-top: 6rem;
        width: 85vw;
        transform: translateX(-8%);
    }



    &__content{
        // border: 1px solid black;
        z-index: 10 !important;
        & > * {
            &:not(:last-child){
                margin-bottom: 3.5rem;
            }
        }
    }

    &__header{  
        text-transform: uppercase;
        font-size: 4rem;
        color: $color-grey-dark-3;

        @media only screen and (max-width:800px){
            font-size: 3.5rem;
        }

        @media only screen and (max-width:400px){
            font-size: 3rem;
        }
    }

    &__text{
        font-size: 2rem;
        color: $color-grey-dark-3;
        line-height: 1.5;

        @media only screen and (max-width:800px){
            font-size: 1.5rem;
        }
        @media only screen and (max-width:800px){
            font-size: 1.3rem;
        }

    }

    &__btn{
        text-decoration: none;
        border: none;
        background-color: $color-primary-dark;
        padding: 1.3rem 2.3rem;
        color: #fff;
        border-radius: 1rem;
        font-size: 1.7rem;
        cursor: pointer;
        transition: all .2s;

        &:hover{
            transform: translateY(-3px) scale(1.04);
            box-shadow: 0 1rem 3rem rgba(#000, .2);
        }

        &:focus{
            outline: none;
        }

        &:active{
            outline: none;
            transform: translateY(0) scale(1);
            box-shadow: 0 1rem 3rem rgba(#000, .1);
        }

        @media only screen and (max-width:800px){
            font-size: 1.2rem;
        }

    }

    &__image{
        position: absolute;
        width: 60%;
        right: 0;
        bottom: 0;
        transform: translateX(20%) translateY(8%);
    }

    &__layer{
        position: absolute;
        left: 24%;
        top:33%;
        // border: 1px solid orangered;
        width: 36%;
        transform: translateX(-32%) translateY(-15%) rotate(220deg);
        z-index: 0;
    }
}