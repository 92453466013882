@keyframes fadeIn{
    0%{
        opacity: 0;
    }

    100%{
        opacity: 1;
    }
}

@keyframes landingAnimation{
    0%{
        transform: translateX(20rem);
    }

    100%{
        transform: translateX(0);
    }
}

@keyframes moveInLeft{
    0%{
        opacity: 0;
        transform: translateX(-20rem);
    }

    80%{
        transform: translateX(1rem);
    }

    100%{
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes moveInLeft-z-1{
    0%{
        opacity: 0;
        transform: translateX(-20rem);
        z-index: -1;
    }

    80%{
        transform: translateX(1rem);
        z-index: -1;
    }

    100%{
        opacity: 1;
        transform: translateX(0);
        z-index: -1;
    }
}

@keyframes moveInHomepageSmallScreen{
    0%{
        opacity: 0;
        transform: translateY(-20%) translateX(-70%);
    }
    100%{
        opacity: 1;
        transform: translateY(0) translateX(-70%);
    }
}

@keyframes moveInTop{
    0%{
        opacity: 0;
        transform: translateY(-10%);
    }
    100%{
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes moveInRight{
    0%{
        opacity: 0;
        transform: translateX(12rem);
    }

    80%{
        transform: translateX(-1rem);
    }

    100%{
        opacity: 1;
        transform: translateX(0);
    }
}


