.business{
    min-height: 100vh;
    padding-top: calc(#{$nav-height} + 5rem);
    margin-left: 15%;
    animation: fadeIn 1s ease-out .2s;
    animation-fill-mode: backwards;
    @media only screen and (max-width:1050px){
        min-height: 150rem;
        margin-left: 10%;
    }

    @media only screen and (max-width:$mobile-L){
        padding-top: $nav-height;
    }
    &__header{
        display: grid;
        grid-template-columns: min-content 1fr;
        grid-row-gap: 2rem;
        margin-bottom: 3rem;
    }

    &__icon{
        width: 10rem;
        @media only screen and (max-width:$mobile-XL){
            width: 7rem
        }
    }

    &__title{
        font-weight: lighter;
        font-size: 2.6rem;
        align-self: end;

        @media only screen and (max-width:$mobile-XL){
            font-size: 2.4rem;
        }
    }

    &__desc{
        grid-column: 1/-1;
        font-size: 1.8rem;
        margin-right: 30%;
        margin-left: 2rem;
        width: 54%;

        @media only screen and (max-width:1050px){
            width: 80%;
        }
        @media only screen and (max-width:$mobile-XL){
           font-size: 1.4rem;
        }
    }

    &__form{
        margin-top: 5rem;
        margin-bottom: 10rem;
    }

    &__checkboxs{
        display: flex;

        & > *{
            margin-left: 2rem;
        }
    }

    &__layer{
        position: absolute;
        bottom: -30%;
        right: 0;
        width: 40%;

        @media only screen and (max-width:$desktop-L){
            display: none;
        }
    }

    &__form{
        @media only screen and (max-width:1325px){
            margin-left: 8%;
        }
        @media only screen and (max-width:1050px){
            margin-top: 15rem;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

.check-group{
   display: flex;
   align-items: center; 
   cursor: pointer;
   input{
       margin-right: 8px;
       cursor: pointer;
   }

   label{
       color: #fff;
       font-size: 1.5rem;
       cursor: pointer;
   }
}
