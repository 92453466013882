*,
*::after,
*::before{
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

@font-face {
    font-family: KeepCalm;
    src: url(../../fonts/KeepCalm-Medium.ttf);
}
@font-face{
    font-family: SFUITextRegular;
    src: url(../../fonts/SFUIText-Regular.ttf);
}

html{
    font-size: 62.5%;

}

body{
    box-sizing: border-box;
    font-family: SFUITextRegular;
}

::selection{
    background-color: $color-primary-dark;
    color: #fff;
}

.text-title{
    font-family: KeepCalm;
}

.ml-2{
    margin-left: 2rem;
}

.mt-2{
    margin-top: 2rem;
}