.modal{
    z-index: 9999;
    background-color: rgba(0,0,0,.5);
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .modal-container{
        width: 50%;
        height: 75vh;
        background-color: white;
        border-radius: 1rem;
        padding: 2rem;
    }
}