.footer {
  background-color: $color-grey-dark-3;
  color: #fff;
  height: 30vh;

  @media only screen and (max-width: $tablet-L) {
    height: calc(40rem + 20px);
  }
  &__content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin: 0 auto;
    margin-top: auto;
    width: 90%;
    height: 85%;
    position: relative;

    @media only screen and (max-width: 750px) {
      grid-template-columns: none;
      grid-template-rows: repeat(3, 1fr);
      grid-row-gap: 1.7rem;
      justify-content: center;
      padding-top: 2rem;
    }
  }

  &__logo_container {
    display: flex;
    align-items: center;
    padding: 10px 0;
    
    @media only screen and (max-height: 720px) {
      justify-content: center;
    }

  }

  &__iso__27001 {
    width: 75px;
  }

  &__bsi {
    width: 96px;
    margin-left: 16px;
    margin-bottom: -4px;
    background-color: white;
    padding: 5px;
    border-radius: 6px;
  }

  &__left {
    margin-top: auto;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.5rem;

    @media only screen and (max-height: 700px) {
      font-size: 1rem;
    }

    @media only screen and (max-width: $mobile-L) {
      text-align: center;
    }
  }

  &__center {
    margin-top: auto;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.5rem;
    cursor: pointer;
    transition: all 0.2s;
    text-decoration: none;
    color: inherit;
    outline: none;

    @media only screen and (max-height: 700px) {
      font-size: 1.2rem;
    }

    &:hover {
      transform: scale(1.05);
      box-shadow: 0 0.5rem 3rem rgba(0, 0, 0, 0.5);
    }

    &:active {
      transform: scale(1);
      box-shadow: 0 0.3rem 2rem rgba(0, 0, 0, 0.3);
    }
  }

  &__right {
    margin-left: auto;
    margin-top: auto;
    text-align: right;
    margin-bottom: -3px;

    @media only screen and (max-width: 750px) {
      grid-row: 1/2;
      text-align: center;
      margin-left: 0;
      margin-bottom: 0;
    }
    @media only screen and (max-height: 700px) {
      font-size: 1.2rem;
    }
  }

  &__logo {
    width: 20rem;
    margin-right: -1rem;

    @media only screen and (max-width: 750px) {
      margin-right: 0;
      margin-left: -1rem;
      width: 15rem;
    }

    @media only screen and (max-height: 700px) {
      width: 15rem;
    }
  }

  &__nama {
    font-size: 1.6rem;
    margin-bottom: 0.5rem;

    @media only screen and (max-height: 700px) {
      font-size: 1.2rem;
    }
  }

  &__text {
    font-size: 1.4rem;
    // width: max-content;
    // text-align: right;
  }
}
