.message{
    width: 50vw;
    height: 50rem;
    box-shadow: 0 1rem 2rem rgba(0,0,0,0.1);
    display: flex;
    border-top-left-radius: 3rem;
    border-bottom-left-radius: 3rem;

    @media  only screen and (max-width:1325px){
        width: 65vw;
        transform: translateX(-10%);
    }

    @media only screen and (max-width:1050px){
        width: 80vw;
        transform: translateX(-5%) translateY(-20%);
        flex-direction: column;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        margin-top: 50%;
        margin-bottom: 60%;
    }

    @media only screen and (max-width:600px){
        margin-top: 85%;
        margin-bottom: 90%;
    }
    
    &__info{
        // border: 1px solid blue;
        width: 6rem;
        width: 55%;
        border-top-left-radius: 3rem;
        border-bottom-left-radius: 3rem;
        background-color: #fff;
        padding-left: 5rem;
        padding-top: 6.5rem;

        @media only screen and (max-width:1200px){
            width: 50%;
        }

        @media only screen and (max-width:1050px){
            border-top-right-radius: 5px;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 0;
            padding-bottom: 3rem;
            width: 100%;
        }

        @media only screen and (max-width:$mobile-L){
            padding-left: 4rem;
        }
        @media only screen and (max-width:$mobile-M){
            padding-left: 3rem;
        }
    }

    &__header{
        font-size: 2.6rem;

        @media only screen and (max-width:$mobile-L){
            font-size: 2.2rem;
        }
    }

    &__description{
        font-size: 1.5rem;
        color: $color-grey-dark-3;
        margin-top: 1rem;
        @media only screen and (max-width:$mobile-L){
            font-size: 1.3rem;
        }
    }

    &__contacts{
        // border: 1px solid black;
        margin-top: 1.5rem;
        display: flex;
        align-items: center;
        padding: 1rem;
        margin-left: 1rem;

        @media only screen and (max-width:1050px){
            margin-left: -1rem;
        }

    }

    &__icon{
        width: 3rem;
    }

    &__text{
        // border: 1px solid black;
        font-size: 1.4rem;
        margin-left: 2rem;

        @media only screen and (max-width:$mobile-L){
            font-size: 1.2rem;
        }

        @media only screen and (max-width:$mobile-S){
            font-size: 1rem;
        }

        p{
            &:not(:last-child){
                margin-bottom: .5rem;
            }
        }
    }

    &__form{
        // border: 1px solid crimson;
        z-index: 10;
        width: 45%;
        padding-top: 6rem;
        padding: 6rem 3rem 2rem 4rem ;
        position: relative;
        background-color: $color-primary-dark;
        background-image: linear-gradient(to bottom, $color-primary-light, $color-primary-darker);

        @media only screen and (max-width:1200px){
            width: 50%;
        }

        @media only screen and (max-width:1050px){
            width: 100%;
            padding-top: 3.5rem;
            padding-bottom: 10rem;
            border-end-end-radius: 5px;
            border-end-start-radius: 5px;
            box-shadow: 0 1rem 2rem rgba(0,0,0,0.1);
        }

        &--extension{
            width: 1rem;
            height: 3rem;
            background-color: $color-primary-dark;
            position: absolute;
            top:2rem;
            width: 5rem !important;
            left: -5%;
            top: 14%;
            clip-path: polygon(0% 50%, 50% 0%, 50% 100%);

            @media only screen and (max-width:1325px){
                left: -6%;
            }

            @media only screen and (max-width:1050px){
                top: -1rem;
                transform: rotate(90deg) !important;
                left: 75%;
            }
        }

        & > * {
            display: block;
            width: 100%;
            border: none;
            font-family: inherit;

            font-family: 'Lato';
            &:not(:last-child){
                margin-bottom: 2rem;
            }
        }

        &__group{
            width: 100%;
            border: 1px solid black;
        }

        &__input{
            font-size: 1.6rem;
            padding: 1rem 2rem;
            border-radius: 10px;
            transition: all .2s;
            background-color: transparent;
            color: #fff;
            border: 2px solid #fff;

            &::placeholder{
                color: #fff;
                opacity: 1;
            }

            &:focus{
                box-shadow: 0 1rem 3rem rgba(0,0,0,.2);
                // border: none;
                outline: none;
            }
            &::placeholder{
                color: #fff;
                opacity: 1;
                font-family: 'Lato';
                font-weight: 300;
                text-transform: uppercase;
            }
            
        }

        &__multirows{
            padding: 1.5rem 2rem;
            border-radius: 10px;
            transition: all .2s;
            background-color: transparent;
            color: #fff;
            border: 2px solid #fff;
            height: 50%;
            transition: all .2s;
            font-size: 1.6rem;

            &::placeholder{
                color: #fff;
                opacity: 1;
                font-family: 'Lato';
                font-weight: 300;
                text-transform: uppercase;
            }

            &:focus{
                box-shadow: 0 1rem 3rem rgba(0,0,0,.2);
                outline: none;
            }
        }

        &__btn{
            padding: 1.5rem 2.3rem;
            border-radius: 10px;
            color: $color-primary-dark;
            cursor: pointer;
            font-weight:bold;
            transition: all .2s;
            background-color: #fff;

            &--arr{
                transition: all .2s;
                font-weight: bold;
            }

            &:hover{
                transform: translateY(-2px) scale(1.02);
                box-shadow: 0 1rem 3rem rgba(0,0,0,.2);


            }

            &:hover &--arr{
                margin-left: .8rem;
            }

            &:focus{
                outline: none;
            }

            &:active{
                outline: none;
                transform: translateY(0) scale(1);
                box-shadow: 0 1rem 2rem rgba(0,0,0,.1);
            }
        }
    }


    &__form-image{
        position: absolute;
        right: 7rem;
        width: 45vw;
        overflow: hidden;
        z-index: 0;
        animation: fadeIn 1s 1.3s ;
        animation-fill-mode: backwards;

        @media  only screen and (max-width:1320px){
            // width: 30vw;
            transform: translateY(14rem) translateX(64%);
        }

        @media only screen and (max-width:1050px){
            display: none;
        }
    }
}