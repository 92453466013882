.news{
    min-height: 100vh;
    padding-top: calc(#{$nav-height} + 5rem);
    padding-left: 15%;
    position: relative;
    padding-bottom: 20rem;

    @media only screen and (max-width:$tablet-S){
        padding-left: 8%;
     }

    @media only screen and (max-width:$mobile-L){
        padding-top: $nav-height;
    }
    
    &__header{
        cursor: pointer;
        display: grid;
        grid-template-columns: min-content 1fr;
        grid-column-gap: 2rem;
        margin-bottom: 3rem;
        animation: fadeIn 1s ease-out .2s;
        animation-fill-mode: backwards;

       
    }

    &__icon{
        width: 10rem;
        @media only screen and (max-width:$tablet-S){
            width: 8rem;
         }
         @media only screen and (max-width:$mobile-S){
            width: 6rem;
         }
    }

    &__title-top{
        font-weight: lighter;
        font-size: 2.6rem;
        align-self: end;
        @media only screen and (max-width:$tablet-S){
            font-size: 2.2rem;
         }
         @media only screen and (max-width:$mobile-S){
            font-size: 2rem;
         }
    }

    &__content{
        // border: 1px solid black;
        width: 80%;
        margin-bottom: 5rem;
        animation: fadeIn 1s ease-out .2s;
        animation-fill-mode: backwards;
        @media only screen and (max-width:$tablet-L){
           width: 90%;
        }
        @media only screen and (max-width:$tablet-S){
            width: 95%;
         }
    }

    &__liquid{
        position: absolute;
        bottom: 0;
        right: 0;
        width: 60%;
    }

    &__items{
        display: grid;
        grid-template-columns: 20% 1fr;
        grid-template-rows: 100%;
        grid-column-gap: 3rem;

        @media only screen and (max-width:$tablet-L){
            grid-template-columns: 30% 1fr;
        }

        @media only screen and (max-width:$mobile-XL){
            grid-template-columns: 40% 1fr;
        }

        @media only screen and (max-width:$mobile-L){
            grid-template-columns: 100%;
            grid-template-rows: max-content max-content;
            grid-row-gap: 1rem;
        }

    }

    &__thumbnail{
        width: 100%;
        height: auto;
        background-color: $color-primary-dark;

    }

    &__isi{
        font-size: 1.8rem;
        @media only screen and (max-width:$tablet-S){
            font-size: 1.6rem;
         }

        @media only screen and (max-width:$mobile-XL){
        font-size: 1.2rem;
        }

        &__title {
            font-weight: bold; 
            font-size: 1.7rem; 
            cursor: pointer; 
        }
        
        &__title:hover {
            color: $color-primary-dark;
        }
        
    }

    &__btn{
        color: $color-primary-dark;
        font-weight: bold;
        margin-top: 1rem;
        cursor: pointer;

        @media only screen and (max-width:$mobile-L){
            text-align: right;
        }
    }

    &__back{
        display: flex;
        flex-direction: row;
        align-items: center;
        color: $color-primary-dark;
        font-weight: bold;   
        margin-top: 2rem;
        cursor: pointer;
        font-size: 1.6rem;

        @media only screen and (max-width:$mobile-XL){
            font-size: 1.4rem;
        }
    }


    &__news-content{
        width: 80%;
        animation: fadeIn 1s ease-out .2s;
        animation-fill-mode: backwards;

        @media only screen and (max-width:$tablet-XL){
            width: 90%;
        }
    }

    &__title{
        font-weight: bolder;
        font-size: 2.6rem;
        margin: 3rem 0;

        @media only screen and (max-width:$desktop-M){
            br{
                display: none;
            }
        }

        @media only screen and (max-width:$tablet-XL){
            font-size: 2.2rem;
        }
        @media only screen and (max-width:$mobile-XL){
            font-size: 1.8rem;
        }

        @media only screen and (max-width:$mobile-S){
            font-size: 1.6em;
            font-weight: bold;
        }
    }

    &__karatop{
        width:50%;

        @media only screen and (max-width:$mobile-XL){
            width: 100%;
        }
    }

    &__text{
        font-size: 1.6rem;
        @media only screen and (max-width:$mobile-XL){
            font-size: 1.4rem;
        }
        p{
            margin-top: 3rem;
        }
    }

    &__karabottom{
        width: 50%;
        margin-top: 3rem;
        @media only screen and (max-width:$mobile-XL){
            width: 100%;
        }
    }
}