

.about{ 
    width: 100vw;
    max-width: 100%;
    // height: 150rem;
    height: min-content;
    padding-bottom: 20rem;
    border: 1px solid black;
    position: relative;
    z-index: -1;

    @media only screen and (max-width:1100px){
        height: max-content;
        padding-bottom: 0;
    }

    &__content{
        height: 100%;
        margin: 0 auto;
        padding-top: 10vh;
        display: flex;
        display: grid;
        grid-template-columns: 1fr repeat(10,11.2rem) 1fr;
        grid-template-rows: 12rem repeat(2, max-content) 8rem max-content 1fr;
        animation: fadeIn 1s ease-out .2s;
        animation-fill-mode: backwards;

        @media only screen and (max-width:1200px){
            grid-template-columns: 1fr repeat(10,1fr) 1fr;
        }
        @media only screen and (max-width:1100px){
            margin-bottom: 20rem;
        }

        @media only screen and (max-width:$mobile-XL){
            margin-bottom: 20rem;
            grid-template-rows: 8rem repeat(2, max-content) 12rem max-content 1fr;
        }
    }

    &__layer{
        position: absolute;
        width: 35%;
        transform: rotate(45deg) ;
        top: 8%;
        left: 12%;
        opacity: .5;
    }

    &__phone{
        position: absolute;
        bottom: 0;
        right: 0;
        width: 35%;


        @media only screen and (max-width:$tablet-XL){
            opacity: .6;
        }


    }

    &__liquid{
        z-index: -1;
        position: absolute;
        bottom: 0;
        right: 0;
        @media only screen and (max-width:$desktop-S){
            width: 80%;
        }
        @media only screen and (max-width:$tablet-XL){
            opacity: .2;
        }
        
    }

    &__vision{
        position: relative;
        display: grid;
        // grid-template-columns: min-content 80rem;
        grid-template-columns: min-content 40rem;
        grid-template-rows: min-content min-content;
        grid-row-gap: 1rem;
        margin-right: 10rem;
        grid-row: 5/6;
        grid-column: 2/7;
        grid-column-gap: 1rem;

        @media only screen and (max-width:$mobile-L){
            grid-template-columns: min-content 25rem;
        }

        @media only screen and (max-width:$mobile-S){
            grid-template-columns: min-content 20rem;
        }
    }

    &__mission{
        display: grid;
        // grid-template-columns: min-content 80rem;
        grid-template-columns: min-content 40rem;
        grid-template-rows: min-content min-content;
        grid-row-gap: 1rem;
        grid-row: 6/7;
        grid-column: 2/-1;
        margin-top: 8rem;
        grid-column-gap: 1rem;
        @media only screen and (max-width:$mobile-L){
            grid-template-columns: min-content 25rem;
        }
        @media only screen and (max-width:$mobile-S){
            grid-template-columns: min-content 20rem;
        }
    }

    &__us{
        grid-row: 2/4;
        grid-column: 2/12;
    }

    &__icon{
        width: 9rem;
        @media only screen and (max-width:$mobile-XL){
           width: 7rem;
        }
    }

    &__header{
        font-size: 3.5rem;
        color: $color-grey-dark-3;
        align-self: end;
        font-weight: lighter;
        @media only screen and (max-width:$mobile-XL){
            font-size: 3rem;
        }

    }

    &__text{
        font-size: 2rem;
        line-height: 1.2;
        color: $color-grey-dark-3;
        grid-column: 1/-1;
        max-width: 90%;
        @media only screen and (max-width:600px){
            font-size: 1.7rem;
        }
    }

    &__policy{
        display: grid;
        grid-template-columns: 75rem;
        grid-template-rows: min-content min-content;
        grid-row-gap: 1rem;
        grid-row: 7/8;
        grid-column: 2/-1;
        margin-top: 8rem;
        margin-bottom: 2rem;
        grid-column-gap: 1rem;
        @media only screen and (max-width:$mobile-XL){
            grid-template-columns: 30rem;
        }
        @media only screen and (max-width:$mobile-S){
            grid-template-columns: 20rem;
        }
        
    }

    &--text{
        // @media only screen and (max-width:$tablet-XL){
        //     text-align: center;
        // }
    }
}

