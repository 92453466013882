.concat-logo{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: min-content;
    margin-bottom: 1.5rem;
    margin-right: .5rem;

    @media only screen and (max-width:$mobile-L){
        margin-bottom: 9px;
    }
   

    &__logo{
        width: 15vw;
    }

    &__logo-text{
        align-self: flex-end;
        margin-top: -2rem;
        margin-right: 1.7rem;
        text-transform: uppercase;
        color: $color-primary-dark;
        font-weight: bold;
        line-height: 1.2;
        font-size: .8vw;

        @media only screen and (max-width:$tablet-XL){
            margin-bottom: 1rem;
            font-size: 9px;
            margin-right: 8px;
        }
        @media only screen and (max-width:$mobile-L){
            font-size: 8px;
            margin-right: 1.5rem;
        }
    }
}

.simple-card{
    box-shadow: $shadow;
    width: 100%;
    border-radius: 1rem;
    padding: 2rem;
    background-color: white;

    .title{
        font-size: 2rem;
        color: $color-primary-dark;
        font-weight: bold;
        padding-bottom: 1rem;
        border-bottom: 1px solid #cecece;
    }
    .detail{
        font-size: 1.7rem;
        margin-top: 1rem;
        line-height: 2.5rem;

        ul{
            padding-left: 3rem;
        }
    }
}