.partner_card {
    background-color: $color-primary-dark;
    color: #fff;
    width: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 1rem;
    padding: 4rem;
    min-height: 160px;
    
    @media only screen and (max-width:$mobile-L){
        min-height: 80px;
        width: 100%;
        padding: 1.5rem 0;
    }

    &__value {
        font-size: xx-large;
        font-weight: bold;

        @media only screen and (max-width:$mobile-L){
            font-size: 20px;
        }
    }
    
    &__title {
        font-size: x-large;

        @media only screen and (max-width:$mobile-L){
            font-size: 18px;
        }
    }
}