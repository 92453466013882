.karir{
    background-color: #fcfcfc;
    &__top{
        height: 85vh;
        background-color: $color-primary-dark;
        border-bottom-left-radius: 20rem;
        border-bottom-right-radius: 20rem;
        display: flex;
        justify-content: center;
        flex-direction: column;
        color: white;
        padding: 0 12%;

        @media only screen and (max-width:$tablet-L){
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            height: 65vh;
        }
    }   

    &__text{
        font-size: 4.5rem;
        font-weight: bold;
        @media only screen and (max-width:$tablet-L) {
            font-size: 2.4rem;
        }
        
    }

    &__text-sm{
        font-size: 2rem;
        font-weight: normal;
        margin-top: 1rem;
        @media only screen and (max-width:$tablet-L) {
            font-size: 1.8rem;
        }
    }

    &__option-container{
        height: 15vh;
        // background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        @media only screen and (max-width:$tablet-L) {
            height: auto;
        }
        
    }

    &__option{
        width: 80%;
        background-color: #efebeb;
        height: 100%;
        border-radius: 10rem;
        margin-top: -15vh;
        box-shadow:$shadow;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 3rem;

        @media only screen and (max-width:$tablet-L) {
            flex-direction: column;
            height: auto;
            border-radius: 6px;
            padding: 2rem;
            margin-top: 1rem;
            width: 90%;
        }

        & > * {
            margin:  0 1rem;
            @media only screen and (max-width:$tablet-L) {
                margin: 1rem;
            }
        }

    }

    &__cari{
        border-radius: 2rem;
        padding: 1.2rem 4rem;
        font-size: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        background-color: $color-primary-dark;
        color: white;
        cursor: pointer;
        @media only screen and (max-width:$tablet-L) {
                width: 100%;
            }
    }

    &__list{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-bottom: 3rem;
        @media only screen and (max-width:$tablet-L) {
            margin-top: 2rem;
            padding-bottom: 0;
        }
    }

    &__detail{
        height: 85vh;
        background-color: $color-primary-dark;
        border-bottom-left-radius: 20rem;
        // border-bottom-right-radius: 30rem;
        display: flex;
        justify-content: center;
        flex-direction: column;
        color: white;
        padding: 0 12%;
    }

    &__btns{
        margin-top: 2rem;
        display: flex;
        align-items: center;
    }

    &__btm{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-bottom: 3rem;
        @media only screen and (max-width:$tablet-L) {
            width: 80%;
            margin: 0 auto;
            margin-top: 2rem;
        }
    }
    
}

.karir-item{
    width: 80%;
    border-radius: 2rem;
    padding: 3rem ;
    box-shadow: $shadow;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;

    @media only screen and (max-width:$tablet-L) {
        width: 90%;
        border-radius: 4px;
        padding: 1.5rem;
        flex-direction: column;
    }
    .left{
        @media only screen and (max-width:$tablet-L) {
            align-self: flex-start;
            margin-bottom: 1rem;
        }
        .name{
            color: $color-primary-dark;
            font-size: 1.8rem;
            font-weight: bold;
        }
        .daerah{
            color: $color-primary;
            font-size: 1.2rem;
        }
    }

    .center{
        display: grid;
        align-items: center;
        grid-template-columns: 1fr 1fr;
        grid-gap: 3rem;
        @media only screen and (max-width:$tablet-L) {
            margin-bottom: 2rem;
        }

        .group{
            display: flex;
            align-items: center;
            &__icon{
                border-radius: 50%;
                width: 1.5rem;
                height: 1.5rem;
                padding: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $color-primary-dark;
                color: white;
            }

            div{
                font-size: 1.5rem;
                margin-left: 1rem;
            }
        }
    }

    button{
        border-radius: 2rem;
        padding: 1.2rem 2rem;
        font-size: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        background-color: $color-primary-dark;
        color: white;
        cursor: pointer;
        
        @media only screen and (max-width:$tablet-L) {
           border-radius: 6px;
           font-size: 1.3rem;
            width: 100%;
           padding: 1rem 1rem;
           background-color: white;
           color: $color-primary;
        }
    }

}

.btn-daftar{
    background-color: white;
    color: $color-primary-dark;
    border-radius: 2rem;
    padding: 1rem 2rem;
    border: none;
    font-weight: bold;
    cursor: pointer;
    transition: all .2s;
    border: 1px solid transparent;
    &:hover{
        background-color: $color-primary-dark;
        color: white;
        border: 1px solid white;
    }

    &--light{
        background-color: $color-primary-dark;
        color: white;
        border: 1px solid white;
        &:hover {
            background-color: white;
            color: $color-primary-dark;
        }
    }
}

.karir-detail{
    width: 80%;
    margin: 3rem auto;
    display: flex;
    justify-content: space-between;
    .left{
        width: 64%;
    }

    .right{
        width: 34%;
    }

    
}

.text-title-2 {
    text-align: center;
    font-size: 3.5rem;
    margin: 2rem 0;
    color: $color-primary-dark;
    font-weight: bold;
}