$breakpoint-md: 768px;

.consent {
    &__container {
        position: fixed;
        bottom: 0;
        left: 0;
        display: block;
        z-index: 9999;
        width: 98vw;
        transform: translate(1vw, -0.5vh);
        padding: 1rem;
        background-color: white;
        display: flex;
        flex-direction: column;
        border-radius: 4px; 

        @media only screen and (min-width: $breakpoint-md){
            width: 360px;
            transform: translate(1vw, -2vh);
        }
    }

    &__header {
        padding: 0.5rem;
        font-weight: bold;
        font-size: 1.4rem;
    }

    &__text {
        padding: 0.5rem;
        text-align: justify;
        width: 100%;
        font-size: 1.4rem;
    }

    &__button-container {
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        margin-top: 0.75rem;
    }

    &__button {
        width: 100%;
        padding: 0.5rem;
        background-color: #16a34a;
        border: none;
        color: white;
        border-radius: 4px;
        font-size: large;

        &:hover {
            cursor: pointer;
            background-color: #22c55e;
            transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            transition-duration: 150ms;
        }

        @media only screen and (min-width: $breakpoint-md){
            width: 30%;
            font-size: small;
        }
    }
}