.autocomplete{
    background-color: white;
    border-radius: 2rem;
    padding: 1.2rem 2rem;
    font-size: 1.8rem;
    display: flex;
    align-items: center;
    width: 100%;

    &__icon{
        color: $color-primary-dark;
    }
    &__result{
        margin-left: 2rem;
        border: none;
        font-size: 1.5rem;
        width: 80%;
        &:focus{
            outline: none;
        }
    }
}