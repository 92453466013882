.term-condition{

    margin-top: 23vh;
    margin-left: 13%;
    padding-top: 10rem;
    margin-bottom: 10rem;
    margin-right: 17%;

    @media only screen and (max-width:450px){
        padding-top: 2rem;
    }

    &__header{
       display: grid;
       grid-template-columns: min-content 1fr;
       grid-gap: 1rem;
       margin-left: 2rem;
       margin-bottom: 5rem;

       @media only screen and (max-width:1100px){
            margin-left: -1rem;
        }
    }   

    &__icon{
        grid-column: 1/2;
        width: 9rem;

        @media only screen and (max-width:450px){
            width: 8rem;
        }
    }

    &__title{
        grid-column: 2/-1;
        font-family: keepCalm;
        font-weight: lighter;
        font-size: 3.1rem;
        align-self: end;

        @media only screen and (max-width:620px){
            font-size: 2.4rem;
        }
        @media only screen and (max-width:420px){
            font-size: 2rem;
        }
    }

    &__warn{
        grid-column: 1/-1;
        font-size: 2rem;
        margin-left: 1rem;

        @media only screen and (max-width:1000px){
            width: 100%;
            line-height: 2;
        }

        @media only screen and (max-width:560px){
            font-size: 1.8rem;
        }

        @media only screen and (max-width:450px){
            font-size: 1.5rem;
        }
    }

    &__text{
        font-size: 1.8rem;
        line-height: 1.5;
    }

    &__text-bold{
        text-transform: uppercase;
        font-family: keepCalm;
        font-size: 1.6rem;
        font-weight: lighter;
    }
}