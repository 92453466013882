//colors
///////// OLD PRIMARY COLOR

 $color-primary: #65BD8C; //green
 $color-primary-light: #04ac4d; //light green
 $color-primary-dark: #04ac4d; //dark green 
 $color-primary-darker: #049040;

$color-grey-light-1:#f7f7f7;
$color-grey-light-2 : #eee;

$color-grey-dark:#777;
$color-grey-dark-2:#999;
$color-grey-dark-3:#333;

$color-white:#fff;
$color-black:#000;

//font
$default-font-size:2.3rem;
$nav-height:15.5rem;
$nav-mobile-height:12.5rem;
$nav-mobile-S-height:9.5rem;

//media variables
$desktop-L:1400px;
$desktop-M:1300px;
$desktop-S:1200px;
$tablet-XL:1100px;
$tablet-L:1000px;
$tablet-S:730px;
$mobile-XL:600px;
$mobile-L:450px;
$mobile-M:400px;
$mobile-S:350px;


$shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
 