.list{

    z-index: 10;
    &__header{
        // width: 100%;
        width: 100%;
        background-color:#fff;
        padding: 1rem 2rem;
        font-size: 1.8rem;
        box-shadow: 0 .2rem 1rem rgba(0,0,0,.2);
        border: 1px solid $color-grey-dark;
        border-radius: 1rem;
        display: grid;
        grid-template-columns: 35% 1fr;
        cursor: pointer;
        transition: all .2s;

        @media only screen and (max-width:$desktop-M){
            grid-template-columns: 50% 1fr;
        }

        @media only screen and (max-width:$tablet-XL){
            grid-template-columns: 95% 1fr;
        }

        @media only screen and (max-width:$mobile-XL){
            font-size: 1.5rem;
        }

        @media only screen and (max-width:$mobile-L){
            font-size: 1.3rem;
            padding: 8px 2rem;
        }

        
        &--active{
            background-color: $color-primary-dark;
            color: #fff;
        }
    }

    &__header--active &__icon-container{
        background-color: #fff;
    }

    &__header--active &__icon-container &__icon{
        color: $color-primary-dark;
        transform: rotate(90deg);
    }

    &__title{
        margin-top: 2px;
        @media only screen and (max-width:$mobile-XL){
            margin-top: 5px;
        }
    }

    &__icon-container{
        background-color: $color-primary-dark;
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all .2s;

        @media only screen and (max-width:$mobile-L){
            width: 2rem;
            height: 2rem;
        }
    }   

    &__icon{
        color: #fff;
        font-weight: bold;
        transition: all .2s;
    }

    &__content{
        margin-left: 3rem;
        font-size: 1.9rem;
        line-height: 1.5;
        margin-top: 2rem;
        color: $color-grey-dark-3;
        @media only screen and (max-width:$tablet-S){
            font-size: 1.6rem;
        }

        @media only screen and (max-width:$mobile-L){
            font-size: 1.3rem;
        }
    }
   
    &__item{
        
        &:last-child{
            margin-bottom: 2rem;
        }
    }
}