

.services{
    width: 100vw;
    height: 100vh;
    max-width: 100%;
    color: $color-grey-dark-3;
    display: grid;
    grid-template-columns:14% 1fr 1fr .1fr 1fr 1fr 1fr;;
    grid-template-rows:$nav-height 1fr 4rem 1fr 2rem 1fr 1fr;
    position: relative;
    
    @media only screen and (max-width:$desktop-S){
        grid-template-columns:14% 1fr 2fr .1fr 1fr 1fr 1fr;;
    }

    @media only screen and (max-width:$tablet-L){
        min-height: 100vh;
        height: auto;
        animation: fadeIn 1s ease-out .2s;
        animation-fill-mode: backwards;
        grid-template-rows:$nav-mobile-height .6fr 4rem 15rem 2rem 18rem 1fr;
    }

    @media only screen and (max-width:$mobile-XL){
        grid-template-rows:7.5rem 12rem 4rem 15rem 2rem 18rem 20rem;
    }

    &__background{
        height: 80%;
        align-self: start;
        position: absolute;
        bottom: 0;
        right: 0;

        @media only screen and (max-width:$tablet-XL){
            @media only screen and (min-height:800px){
                opacity: .1;
            }
        }

        @media only screen and (max-width:$tablet-L){
            opacity: .1;
            z-index: -1;
        }

        @media only screen and (max-width:$mobile-XL){
            height: 60%;
        }

        @media only screen and (max-width:$mobile-L){
            height: 45%;
        }
    }

    &__layer{
        grid-column: 4/6;
        grid-row: 4/9;
        width: 150%;
        transform: translateX(-5%) translateY(-27%);
    }

    &__title-top{
        grid-row: 2/3;
        grid-column: 2/4;
        align-self: end;
        font-size: 3.5rem;
        font-weight: lighter;
        animation: fadeIn 1s ease-out .2s;
        animation-fill-mode: backwards;
        @media only screen and (max-width:$tablet-L){
            grid-column: 2/-1;
        }

        @media only screen and (max-width:$mobile-XL){
            font-size: 3rem;
        }
    }

    &__1{
        grid-column: 2/4;
        grid-row: 4/5;
        animation: fadeIn 1s ease-out .2s;
        animation-fill-mode: backwards;
        @media only screen and (max-width:$tablet-L){
            grid-column: 2/7;
        }
    }

    &__2{
        grid-row: 6/7;
        grid-column: 2/4;
        animation: fadeIn 1s ease-out .2s;
        animation-fill-mode: backwards;
        @media only screen and (max-width:$tablet-L){
            grid-column: 2/7;
        }
    }

    &__title{
        font-size: 3rem;
        font-weight: lighter;
        
        @media only screen and (max-width:$mobile-XL){
            font-size: 2.5rem;
        }
    }

    &__text{
        margin-top: 1rem;
        font-size: 1.8rem;
        @media only screen and (max-width:$mobile-XL){
            font-size: 1.5rem;
        }
    }

    &__btn{
        cursor: pointer;
        grid-row: 7/8;
        grid-column: 2/4;
        justify-self: start;
        align-self: start;
        margin-top: 1rem;
        border: none;
        border-radius: 10rem;
        padding: 1.8rem 5.1rem;
        font-size: 1.7rem;
        background-image: linear-gradient(to bottom, $color-primary-light, $color-primary-darker);
        color: #fff;
        box-shadow: 0 5px 1rem rgba(0,0,0,.1);
        text-decoration: none;
        transition: all .2s;
        animation: fadeIn 1s ease-out .2s;
        animation-fill-mode: backwards;

        @media only screen and (max-width:$mobile-L){
            padding: 1.5rem 4.1rem;
        }
        @media only screen and (max-width:$mobile-S){
            font-size: 1.4rem;
        }

        &:hover{
            transform: scale(1.03) translateY(-2px);
            box-shadow: 0 5px 1rem rgba(0,0,0,.2);
        }

        &:focus{
            transform: scale(1) translateY(0);
            box-shadow: 0 2px 1rem rgba(0,0,0,.1);
        }
    }

    &__playstore{
        position: absolute;
        width: 25rem;
        right: 8%;
        cursor: pointer;
        bottom: 5%;
    }
}