.loading-container{
    background-color: $color-primary-dark;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading{
    width: 20rem;
    height: 20rem;
    border-radius: 50%;
    border-top: 1rem solid $color-primary-light;
    position: relative;
    animation: a1 2s linear infinite;

    &::before,
    &::after{
        content: "";
        width: 20rem;
        height: 20rem;
        position: absolute;
        left: 0;
        top:-10px;
        box-sizing: border-box;
        border-radius: 50%;
    }

    &::before{
        border-top: 1rem solid $color-primary-light;
        transform: rotate(120deg);
    }

    &::after{
        border-top: 1rem solid $color-primary-light;
        transform: rotate(240deg);
    }

    span{
        font-size: 2rem;
        position: absolute;
        width: 20rem;
        height: 20rem;
        color: #fff;
        text-align: center;
        line-height: 20rem;
        animation: a2 2s linear infinite;
    }
}

@keyframes a1{
    to{
        transform: rotate(360deg);
    }
}

@keyframes a2{
    to{
        transform: rotate(-360deg);
    }
}