.section{
    overflow: hidden;
}

.contact{
    width: 100vw;
    max-width: 100%;
    background-color: $color-primary-dark;
    display: flex;
    align-items: center;
    min-height: 100vh;
    overflow: hidden;

    @media only screen and (max-height:1000px){
        // height: 120vh;
    }
    
    &__content{
        margin-left: 13%;
        animation: moveInLeft 1s ease-out .2s;
        animation-fill-mode: backwards;
        z-index: 10;

        @media only screen and (max-width:1050px){
            animation: fadeIn 1s ease-out .2s;
            animation-fill-mode: backwards;
        }
    }
}